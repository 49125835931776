import { useEffect, useMemo, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import logger from '@/utils/logger';
import getConfig from 'next/config';
import { useSession } from 'next-auth/react';

export type AnalyticsEventTypes = 'click' | 'success' | 'load' | 'error';

export type AnalyticsProperties = {
    source?: string;
};

export interface ITrackEvents {
    eventName: string;
    eventType: AnalyticsEventTypes;
    properties?: AnalyticsProperties;
}

export const isProduction = () => {
    return getConfig().publicRuntimeConfig.env == 'production';
};

export default function useAnalytics() {
    const isProductionEnv = isProduction();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const session = useSession();
    const mixpanelToken = getConfig().publicRuntimeConfig.mixpanelToken;
    const env = getConfig().publicRuntimeConfig.env;

    const shouldTrackEvents = useMemo(() => isProductionEnv && mixpanelToken, [isProductionEnv, mixpanelToken]);

    // init mixpanel.
    useEffect(() => {
        if (shouldTrackEvents && !isLoaded)
            mixpanel.init(mixpanelToken, {
                loaded: () => {
                    setIsLoaded(true);
                    logger.info('Mixpanel loaded');
                }
            });
    }, [shouldTrackEvents, isLoaded, mixpanelToken]);

    // identify user.
    useEffect(() => {
        if (isLoaded && session.data) {
            logger.debug({ session }, 'identifying mixpanel user');
            mixpanel.identify(session.data.user?.email);
            mixpanel.people.set('email', session.data.user?.email);
            mixpanel.people.set('id', session.data.user?.id);
            mixpanel.register({
                platform: 'hazmanina app',
                environment: env
            });
        }
    }, [env, isLoaded, session, session.data]);

    const logEvent = ({ eventName, eventType, properties }: ITrackEvents) => {
        logger.info({ eventName: `${eventName} ${eventType}`, properties }, 'Analytics event');
    };

    const trackEvent = ({ eventName, eventType, properties }: ITrackEvents) => {
        if (mixpanel.get_distinct_id()) {
            eventName = `${eventType}: ${eventName}`;
            mixpanel.track(eventName, {
                ...properties
            });
        } else {
            logger.error({ eventName }, 'error tracking event, mixpanel not loaded.');
        }
    };

    return {
        track: shouldTrackEvents ? trackEvent : logEvent
    };
}
